import React from 'react';
import { Stages, StagesModal, StatusColor, StatusOpacity } from 'config/enums';
import { Placeholder, TimelineItems } from 'config/types';
import * as Mammoth from 'mammoth/mammoth.browser';
import { Buffer } from 'buffer';
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Divider,
  Input,
  message,
  Row,
  Slider,
  Space,
  Switch,
  TabsProps,
  Tag,
  Typography,
  Upload,
  UploadProps,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faCode,
  faRefresh,
  faSave,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';

export const nextStage = (stage: number) => {
  const itemsTmp: TimelineItems[] = [];
  // console.log('nextStage', stage);
  switch (stage) {
    case Stages.CHOOSE_TYPE:
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      itemsTmp.push({
        color: StatusColor.WAIT,
        opacity: StatusOpacity.WAIT,
        disable: true,
      });
      itemsTmp.push({
        color: StatusColor.WAIT,
        opacity: StatusOpacity.WAIT,
        disable: true,
      });
      break;
    case Stages.EDIT_CONTRACT:
    case Stages.CREATE_LINK:
      itemsTmp.push({
        color: StatusColor.SUCCESS,
        opacity: StatusOpacity.ACTIVE,
        disable: true,
      });
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      break;
    case Stages.CLEAR:
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      itemsTmp.push({
        color: StatusColor.WAIT,
        opacity: StatusOpacity.WAIT,
        disable: true,
      });
      itemsTmp.push({
        color: StatusColor.WAIT,
        opacity: StatusOpacity.WAIT,
        disable: true,
      });
      break;
  }
  return itemsTmp;
};
export const nextStageModal = (stage: number) => {
  const itemsTmp: TimelineItems[] = [];
  switch (stage) {
    case StagesModal.MODAL_DATA:
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      itemsTmp.push({
        color: StatusColor.WAIT,
        opacity: StatusOpacity.WAIT,
        disable: true,
      });
      break;
    case StagesModal.MODAL_SIGN:
      itemsTmp.push({
        color: StatusColor.SUCCESS,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      itemsTmp.push({
        color: StatusColor.ACTIVE,
        opacity: StatusOpacity.ACTIVE,
        disable: false,
      });
      break;
  }
  return itemsTmp;
};
export const toDataURL = (id?: number, url?: string, callback?: any) => {
  let img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = function () {
    let imgTmp = document.getElementById(`Image${id}`);
    let canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    let ctx = canvas.getContext('2d');
    // let dataURL;
    canvas.height = imgTmp.offsetHeight;
    canvas.width = imgTmp.offsetWidth;
    ctx.drawImage(this as CanvasImageSource, 0, 0);
    let dataURL = canvas.toDataURL();
    callback(dataURL);
  };
  img.src = url;
  if (img.complete || img.complete === undefined) {
    img.src =
      'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
    img.src = url;
  }
};
export const docx2html = (content: ArrayBuffer, callback?: any) => {
  Mammoth.convertToHtml({ arrayBuffer: content })
    .then(function (result) {
      callback(result.value); // The generated HTML
      // let messages = result.messages; // Any messages, such as warnings during conversion
    })
    .catch(function (error) {
      console.error(error);
    });
};
export const encodeUserInfo = (user: any) => {
  return Buffer.from(
    `${user?.fullName}:${user?.primaryEmailAddress?.emailAddress}:${user?.id}`
  ).toString('base64');
};

const props: UploadProps = {
  beforeUpload: (file) => {
    const isPNG = file.type === 'image/png';
    if (!isPNG) {
      message.error(`${file.name} is not a png file`);
    }
    return isPNG || Upload.LIST_IGNORE;
  },
  onChange: (info) => {
    console.log(info.fileList);
  },
};
export const getSettingsTab = (
  isActivePlan: boolean,
  quantity: number,
  isBtnLoad: boolean,
  onClick: (data: any) => void,
  onSliderChange: (data: any) => void
): TabsProps['items'] => {
  const { Text } = Typography;
  return [
    {
      key: '1',
      label: 'Billing',
      children: (
        <Space wrap size={32}>
          <Card
            title={
              <Space>
                <Text>Trial</Text>
                {!isActivePlan && <Tag color="green">Current plan</Tag>}
              </Space>
            }
            style={{ width: 330 }}
            type="inner"
          >
            <Row
              style={{ margin: '16px 0', minHeight: 55 }}
              wrap={false}
              justify="center"
              align={'middle'}
            >
              <Col>
                <Text strong>$0 / 14 days</Text>
              </Col>
            </Row>
            <Divider />
            <Row style={{ margin: '16px 0' }} wrap={false} align="middle">
              <Col>
                <Space direction="vertical">
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Up to 15 created contracts in 14 days
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Up to 5 templates
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Email Support
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    2 recipients per contract
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleXmark}
                        color="#aaa"
                      />
                    }
                    bordered={false}
                  >
                    Workflow builder
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleXmark}
                        color="#aaa"
                      />
                    }
                    bordered={false}
                  >
                    Full API access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleXmark}
                        color="#aaa"
                      />
                    }
                    bordered={false}
                  >
                    Full React components access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleXmark}
                        color="#aaa"
                      />
                    }
                    bordered={false}
                  >
                    Volume discounts
                  </Tag>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Button block disabled>
              Free trial
            </Button>
          </Card>
          <Card
            title={
              <Space>
                <Text>Pro</Text>
                {isActivePlan && <Tag color="green">Current plan</Tag>}
              </Space>
            }
            style={{ width: 330 }}
            type="inner"
          >
            <Row
              style={{ margin: '16px 0 0 0', minHeight: 20 }}
              wrap={false}
              justify="center"
            >
              <Col>
                <Text strong>${quantity} / month</Text>
              </Col>
            </Row>
            <Row style={{ minHeight: 20 }} wrap={false} justify="center">
              <Col flex={'auto'}>
                <Slider
                  tooltip={{ open: false }}
                  min={20}
                  max={1200}
                  step={20}
                  value={quantity}
                  onChange={onSliderChange}
                  disabled={isActivePlan}
                />
              </Col>
            </Row>
            <Divider />
            <Row style={{ margin: '16px 0' }} wrap={false} align="middle">
              <Col>
                <Space direction="vertical">
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Up to <b>{quantity}</b> created contracts / month
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Up to <b>{Math.floor(quantity / 2)}</b> templates
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Email Support
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Up to 20 recipients per contract
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Workflow builder
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Full API access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Full React components access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleXmark}
                        color="#aaa"
                      />
                    }
                    bordered={false}
                  >
                    Volume discounts
                  </Tag>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Button type="primary" block loading={isBtnLoad} onClick={onClick}>
              {isActivePlan ? (
                <>Manage subscription</>
              ) : (
                <>Change plan to ${quantity} / mo</>
              )}
            </Button>
          </Card>
          <Card
            title={
              <Space>
                <Text>Enterprise</Text>
              </Space>
            }
            style={{ width: 330 }}
            type="inner"
          >
            <Row
              style={{ margin: '16px 0', minHeight: 55 }}
              wrap={false}
              justify="center"
              align={'middle'}
            >
              <Col>
                <Text strong>Custom</Text>
              </Col>
            </Row>
            <Divider />
            <Row style={{ margin: '16px 0' }} wrap={false} align="middle">
              <Col>
                <Space direction="vertical">
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Unlimited contracts
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Unlimited templates
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Priority Support
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Unlimited recipients per contract
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Workflow builder
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Full API access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Full React components access
                  </Tag>
                  <Tag
                    icon={
                      <FontAwesomeIcon
                        size="sm"
                        style={{ paddingRight: 8 }}
                        icon={faCircleCheck}
                      />
                    }
                    bordered={false}
                  >
                    Volume discounts
                  </Tag>
                </Space>
              </Col>
            </Row>
            <Divider />
            <Button
              type="primary"
              block
              href="mailto:ilia@sendforsign.com?subject=Enterprise plan&body=How can we help?"
            >
              Contact us
            </Button>
          </Card>
        </Space>
      ),
    },
    {
      key: '2',
      label: 'Notifications',
      children: (
        <Space wrap size={32}>
          <Card
            title={<Text>Email notifications</Text>}
            style={{ width: 400 }}
            type="inner"
          >
            <Row style={{ margin: '16px 0' }} wrap={false} align="middle">
              <Col>
                <Text>Send an email on signing</Text>
              </Col>
              <Col flex={'auto'}></Col>
              <Col>
                <Button
                  icon={<FontAwesomeIcon size="sm" icon={faCode} />}
                  href="https://about.sendforsign.com/api-reference/settings/email_notifications"
                  target="_blank"
                  size="small"
                >
                  API
                </Button>
              </Col>
            </Row>
            <Row style={{ margin: '16px 0' }} wrap={false} align="middle">
              <Col>
                <Text>Send an email on approval</Text>
              </Col>
              <Col flex={'auto'}></Col>
              <Col>
                <Button
                  icon={<FontAwesomeIcon size="sm" icon={faCode} />}
                  href="https://about.sendforsign.com/api-reference/settings/email_notifications"
                  target="_blank"
                  size="small"
                >
                  API
                </Button>
              </Col>
            </Row>
          </Card>
        </Space>
      ),
    },
  ];
};
export const delColorFromHtml = (value: string) => {
  let textTmp = value;
  // return value;
  let tag = `<span style="`;
  let array = textTmp?.split(tag);
  let resultText = '';
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (array.length > 1) {
        if (i === 0) {
          resultText += array[i];
        } else {
          resultText += `<span style="`;
          let lineArr = [];
          for (let k = 0; k <= 5; k++) {
            switch (k) {
              case 0:
                tag = `;"><placeholder`;
                break;
              case 1:
                tag = `;"><date`;
                break;
              case 2:
                tag = `;"><fullname`;
                break;
              case 3:
                tag = `;"><email`;
                break;
              case 4:
                tag = `;"><sign`;
                break;
              case 5:
                tag = `;"><initials`;
                break;
            }
            lineArr = array[i].split(tag);
            if (lineArr.length === 2) {
              break;
            }
          }
          if (lineArr.length === 2) {
            for (let j = 0; j < lineArr.length; j++) {
              if (j === 0) {
                resultText += tag;
              } else {
                resultText += lineArr[j];
              }
            }
          } else {
            resultText += array[i];
          }
        }
      } else {
        resultText = array[i];
      }
    }
    textTmp = resultText;
  }
  return textTmp;
};
export const changeValueInTag = (
  id: number,
  value: string,
  text: string,
  color: string,
  placeholders: Placeholder[]
) => {
  let textTmp = text;
  let contenteditable = false;

  for (let i = 0; i < placeholders.length; i++) {
    const findTag = `"placeholderClass${placeholders[i].id}" contenteditable="false"`;
    if (textTmp.includes(findTag)) {
      contenteditable = true;
      break;
    }
  }
  let tag = `<placeholder${id} class=`;
  let array = textTmp?.split(tag);
  let resultText = '';
  if (array) {
    for (let i = 0; i < array.length; i++) {
      if (array.length > 1) {
        if (i === 0) {
          resultText += array[i];
        } else {
          resultText += `<placeholder${id} class=`;
          tag = `</placeholder${id}>`;
          const lineArr = array[i].split(tag);
          for (let j = 0; j < lineArr.length; j++) {
            if (j === 0) {
              tag = contenteditable
                ? `"placeholderClass${id}" contenteditable="false"`
                : `"placeholderClass${id}"`;
              const elArray = lineArr[j].split(tag);
              for (let k = 0; k < elArray.length; k++) {
                if (k === 0) {
                  resultText += contenteditable
                    ? `${elArray[k]}"placeholderClass${id}" contenteditable="false" style="background-color:${color};">`
                    : `${elArray[k]}"placeholderClass${id}" style="background-color:${color};">`;
                } else {
                  resultText += `${value}</placeholder${id}>`;
                }
              }
            } else {
              resultText += lineArr[j];
            }
          }
        }
      } else {
        resultText = array[i];
      }
    }
    textTmp = resultText;
  }
  return textTmp;
};
