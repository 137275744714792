import React, { useEffect, useRef, useState } from 'react';
import * as Quillname from 'quill';
import QuillBetterTable from 'quill-better-table';
import { Button, Card, Space, Spin, Typography } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch, useSelector } from 'react-redux';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import './readonly-block.css';
import {
  contractSelector,
  contractSignSelector,
  contractValueSelector,
  pdfFileLoadSelector,
  placeholdersFillingSelector,
  placeholdersSelector,
  setContractSign,
  setContractValue,
  setPdfFileLoad,
  setPlaceholdersFilling,
  setSign,
  shareLinkViewSelector,
  signSelector,
} from 'slices/app-slice';
import { useSaveContractValueMutation } from 'slices/contract-api-slice';
import {
  useSendEmailsSignByShareLinkMutation,
  useSendEmailsSignPdfByShareLinkMutation,
} from 'slices/contract-email-api-slice';
import {
  ContractTypes,
  EventStatuses,
  PlaceholderView,
  ShareLinkView,
} from 'config/enums';
import PDFMerger from 'pdf-merger-js/browser';
import { pdf } from '@react-pdf/renderer';
import { PdfViewer } from 'components/pdf-viewer/pdf-viewer';
import { useGetContractSignsByShareLinkQuery } from 'slices/contract-sign-api-slice';
import { PdfSign } from 'components/pdf-sign/pdf-sign';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
import { useGetContractEventsByShareLinkQuery } from 'slices/contract-event-api-slice';
import { ContractEvent, PagePlaceholder, Row } from 'config/types';
import { addBlotClass } from 'utils/utils';
import { PdfAuditTrail } from 'components/pdf-audit-trail/pdf-audit-trail';

const Quill: any = Quillname.default;
Quill.register(
  {
    'modules/better-table': QuillBetterTable,
  },
  true
);

for (let index = 1; index <= 40; index++) {
  addBlotClass(index);
}
export const ReadOnlyBlock = () => {
  const dispatch = useDispatch();
  dayjs.extend(utc);

  const { setArrayBuffer, getArrayBuffer } = useSaveArrayBuffer();
  const quillRef = useRef(null);
  const contract = useSelector(contractSelector);
  const sign = useSelector(signSelector);
  const contractSign = useSelector(contractSignSelector);
  const shareLinkView = useSelector(shareLinkViewSelector);
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const placeholdersFilling = useSelector(placeholdersFillingSelector);
  const contractValue = useSelector(contractValueSelector);
  const placeholders = useSelector(placeholdersSelector);
  const [spinLoad, setSpinLoad] = useState(true);
  const [offsetHeight, setOffsetHeight] = useState(0);
  const pagePlaceholders = useRef<PagePlaceholder[]>([]);
  const [currPagePlaceholders, setCurrPagePlaceholders] = useState<
    PagePlaceholder[]
  >([]);
  // const [rows, setRows] = useState<Row[]>([]);
  const rows = useRef<Row[]>([]);
  const { Title, Text } = Typography;
  const div = document.getElementById(`page_0`);
  const divFill = useRef(false);

  const [saveValue] = useSaveContractValueMutation();
  const [sendEmail] = useSendEmailsSignByShareLinkMutation();
  const [sendEmailPdf] = useSendEmailsSignPdfByShareLinkMutation();

  const { data: contractSignsData, refetch: contractSignsRefetch } =
    useGetContractSignsByShareLinkQuery(contract.shareLink, {
      skip: contract.shareLink ? false : true,
    });
  const { data: contractEventsData } = useGetContractEventsByShareLinkQuery(
    { shareLink: contract.shareLink },
    {
      skip: contract.shareLink ? false : true,
    }
  );
  const { data: signsData } = useGetContractSignsByShareLinkQuery(
    contract.shareLink,
    {
      skip: contract.shareLink && contract.audit ? false : true,
    }
  );
  console.log('div ', div?.offsetHeight);
  useEffect(() => {
    if (div && !divFill.current) {
      console.log('divFill.current', divFill.current);
      setOffsetHeight(div?.offsetHeight as number);
      divFill.current = true;
      if (contract.audit) {
        contractSignsRefetch();
      }
    }
  }, [div]);
  useEffect(() => {
    if (contract.contractType.toString() !== ContractTypes.PDF.toString()) {
      quillRef.current = new Quill('#editor-container', {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              ['blockquote'],

              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ direction: 'rtl' }], // text direction

              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ font: [] }],
              [{ align: [] }],

              ['link', 'image', 'table'],

              ['clean'],
            ],
          },
          table: false, // disable table module
          'better-table': {
            operationMenu: {
              items: {
                unmergeCells: {
                  text: 'Another unmerge cells name',
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
          history: {
            delay: 5000,
            maxStack: 5000,
            userOnly: true,
          },
        },
        readOnly: true,
        scrollingContainer: document.querySelector('html'),
        theme: 'bubble',
      });
    }
  }, []);

  useEffect(() => {
    if (contract.contractType.toString() !== ContractTypes.PDF.toString()) {
      if (contract.contractValue && !contract.audit) {
        // console.log('contractEventsData', contractEventsData);
        if (contract.contractType.toString() !== ContractTypes.PDF.toString()) {
          quillRef.current.clipboard.dangerouslyPasteHTML(
            contract.contractValue
          );
        }
        setSpinLoad(false);
      } else if (contract.contractValue && contract.audit) {
        console.log('contract', contract);
        if (signsData && signsData.length > 0 && contract.audit) {
          quillRef.current.clipboard.dangerouslyPasteHTML(
            auditTrailList(contract.contractValue)
          );
          setSpinLoad(false);
        } else {
          quillRef.current.clipboard.dangerouslyPasteHTML(
            contract.contractValue
          );
          setSpinLoad(false);
        }
      }
    } else {
      console.log('1');
      setSpinLoad(false);
    }
  }, [signsData, contract]);

  useEffect(() => {
    if (
      sign &&
      contractSign &&
      contract.contractType.toString() !== ContractTypes.PDF.toString()
    ) {
      console.log('2');
      let textTmp =
        quillRef.current.root.innerHTML +
        `<br></br><p><img src='${sign}' alt="signature" /></p>`;
      textTmp = textTmp + `<p>Name: ${contractSign.fullName}</p>`;
      textTmp = textTmp + `<p>Email: ${contractSign.email}</p>`;
      textTmp =
        textTmp +
        `<p>Timestamp: ${dayjs(contractSign.createTime)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss')} GMT</p>`;
      quillRef.current.clipboard.dangerouslyPasteHTML(textTmp);
      dispatch(setSign(null));
      dispatch(setContractSign({}));
      sendPdf(textTmp);
    } else if (
      sign &&
      contractSign &&
      contract.contractType.toString() === ContractTypes.PDF.toString()
    ) {
      console.log('3');
      contractSignsRefetch();
    }
  }, [sign]);

  useEffect(() => {
    if (contractEventsData && contractSignsData) {
      console.log('4');
      let rowsTmp: Row[] = contractEventsData
        .filter(
          (contractEventData: ContractEvent) =>
            contractEventData.status.toString() ===
            EventStatuses.SIGNED.toString()
        )
        ?.map((contractEventData: ContractEvent) => {
          let row: Row = {};
          if (contractEventData.ipInfo) {
            const json = JSON.parse(contractEventData.ipInfo);
            if (json) {
              row.json = json;
            }
          }
          const signFind = contractSignsData.find(
            (signData) =>
              signData.email === contractEventData.email &&
              signData.fullName === contractEventData.name
          );
          if (signFind) {
            row.base64 = signFind.base64;
          }
          row.email = contractEventData.email;
          row.name = contractEventData.name;
          row.createTime = `${dayjs(contractEventData.createTime).format(
            'YYYY-MM-DD @ HH:mm:ss'
          )} GMT`;
          return row;
        });
      rows.current = rowsTmp;
    }
  }, [contractSignsData, contractEventsData]);
  useEffect(() => {
    const render = async () => {
      console.log('5');
      // debugger;
      let merger = new PDFMerger();
      let arrayBuffer: ArrayBuffer = (await getArrayBuffer(
        'pdfFileOriginal'
      )) as ArrayBuffer;
      await merger.add(arrayBuffer);

      let blob = await pdf(<PdfSign signs={contractSignsData} />).toBlob();
      arrayBuffer = await blob.arrayBuffer();
      await merger.add(arrayBuffer);

      if (contract.audit) {
        const auditTrail = await pdf(
          <PdfAuditTrail contract={contract} rows={rows.current} />
        ).toBlob();
        await merger.add(await auditTrail.arrayBuffer());
        //   mergedPdf = await addPlaceholderToPdf(mergedPdf);
        //   // const blob = new Blob([mergedPdf], { type: 'application/pdf' });
      }

      let mergedPdfBlob = await merger.saveAsBlob();
      let mergedPdf = await mergedPdfBlob.arrayBuffer();
      await setArrayBuffer('pdfFile', mergedPdf);
      dispatch(setPdfFileLoad(pdfFileLoad + 1));
      if (sign) {
        sendPdf('', false);
        dispatch(setSign(null));
      }
    };
    if (
      contractSignsData &&
      contractSignsData.length > 0 &&
      contract.contractType.toString() === ContractTypes.PDF.toString()
    ) {
      render();
    }
  }, [contractSignsData]);
  useEffect(() => {
    if (
      placeholdersFilling &&
      contract.contractType.toString() !== ContractTypes.PDF.toString()
    ) {
      quillRef.current.clipboard.dangerouslyPasteHTML(
        contractValue.contractValue
      );
      saveValueDb(contractValue.contractValue);
      dispatch(setPlaceholdersFilling(false));
    }
  }, [placeholdersFilling]);

  useEffect(() => {
    if (placeholders && placeholders.length > 0 && contractSignsData) {
      console.log(
        'placeholders contractSignsData',
        placeholders,
        contractSignsData
      );
      console.log('6');

      let pagePlaceholdersTmp: PagePlaceholder[] = [];
      for (let i = 0; i < placeholders.length; i++) {
        if (placeholders[i].insertion && placeholders[i].insertion.length > 0) {
          for (let j = 0; j < placeholders[i].insertion.length; j++) {
            const insertion = placeholders[i].insertion[j];
            let base64 = '';
            if (
              placeholders[i].view.toString() ===
                PlaceholderView.SIGNATURE.toString() &&
              contractSignsData &&
              contractSignsData.length > 0
            ) {
              const findSign = contractSignsData.find(
                (contractSignData) =>
                  contractSignData.shareLink ===
                  placeholders[i].externalRecipientKey
              );
              if (findSign) {
                base64 = findSign.base64;
              }
            }
            let owner = false;
            if (placeholders[i].externalRecipientKey === contract.shareLink) {
              owner = true;
            }
            pagePlaceholdersTmp.push({
              pageId: insertion.pageId,
              id: insertion.id,
              placeholderKey: placeholders[i].placeholderKey,
              value: placeholders[i].value,
              name: placeholders[i].name,
              positionX: parseInt(insertion.positionX.toString(), 10),
              positionY: parseInt(insertion.positionY.toString(), 10),
              width: parseInt(insertion.width.toString(), 10),
              height: parseInt(insertion.height.toString(), 10),
              view: placeholders[i].view,
              base64: base64,
              owner: owner,
            });
          }
        }
      }
      setCurrPagePlaceholders(pagePlaceholdersTmp);
      pagePlaceholders.current = pagePlaceholdersTmp;
    }
  }, [placeholders, contractSignsData]);

  const addPlaceholderToPdf = async (content: ArrayBuffer) => {
    console.log('7');
    const pdfDoc = await PDFDocument.load(content);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const pages = pdfDoc.getPages();
    pagePlaceholders.current.sort((a, b) => {
      if (
        parseInt(a.pageId.toString(), 10) < parseInt(b.pageId.toString(), 10)
      ) {
        return -1;
      }
      if (
        parseInt(a.pageId.toString(), 10) > parseInt(b.pageId.toString(), 10)
      ) {
        return 1;
      }
      return 0;
    });
    for (let i = 0; i < pagePlaceholders.current.length; i++) {
      const scale = pages[pagePlaceholders.current[i].pageId].getWidth() / 1000;
      const y = parseInt(
        (
          parseInt(
            offsetHeight > 0
              ? offsetHeight.toString()
              : pages[pagePlaceholders.current[i].pageId]
                  .getHeight()
                  .toString(),
            10
          ) -
          parseInt(pagePlaceholders.current[i].positionY.toString(), 10) -
          parseInt(pagePlaceholders.current[i].height.toString(), 10)
        ).toString()
      );
      console.log(
        'div?.offsetHeight',
        pagePlaceholders.current[i].positionY,
        pagePlaceholders.current[i].height,
        pages[pagePlaceholders.current[i].pageId].getHeight(),
        pages[pagePlaceholders.current[i].pageId].getWidth(),
        div,
        offsetHeight,
        scale,
        y
      );
      if (
        pagePlaceholders.current[i].view.toString() ===
        PlaceholderView.SIGNATURE.toString()
      ) {
        pages[pagePlaceholders.current[i].pageId].drawRectangle({
          x: pagePlaceholders.current[i].positionX * scale,
          y: y * scale,
          width: pagePlaceholders.current[i].width * scale,
          height: pagePlaceholders.current[i].height * scale,
          // rotate: degrees(-15),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          color: rgb(1, 1, 1),
          // opacity: 0.5,
          borderOpacity: 0.75,
        });
        if (pagePlaceholders.current[i].base64) {
          const pngImage = await pdfDoc.embedPng(
            pagePlaceholders.current[i].base64
          );
          pages[pagePlaceholders.current[i].pageId].drawImage(pngImage, {
            x: pagePlaceholders.current[i].positionX * scale,
            y: y * scale,
            width: pagePlaceholders.current[i].width * scale,
            height: pagePlaceholders.current[i].height * scale,
          });
        }
      } else {
        pages[pagePlaceholders.current[i].pageId].drawRectangle({
          x: pagePlaceholders.current[i].positionX * scale,
          y: y * scale,
          width: pagePlaceholders.current[i].width * scale,
          height: pagePlaceholders.current[i].height * scale,
          // rotate: degrees(-15),
          borderWidth: 1,
          borderColor: rgb(0, 0, 0),
          color: rgb(1, 1, 1),
          // opacity: 0.5,
          borderOpacity: 0.75,
        });
        pages[pagePlaceholders.current[i].pageId].drawText(
          pagePlaceholders.current[i].value
            ? pagePlaceholders.current[i].value
            : pagePlaceholders.current[i].name,
          {
            x: pagePlaceholders.current[i].positionX * scale,
            y:
              (y +
                parseInt(pagePlaceholders.current[i].height.toString(), 10)) *
                scale -
              12,
            font: helveticaFont,
            size: 13.5,
            lineHeight: 20,
            color: rgb(0, 0, 0),
            opacity: 1,
            maxWidth: pagePlaceholders.current[i].width * scale,
          }
        );
      }
    }
    const pdfBytes = await pdfDoc.save();
    const merger = new PDFMerger();

    await merger.add(pdfBytes.buffer);

    // debugger;
    const auditTrail = await pdf(
      <PdfAuditTrail contract={contract} rows={rows.current} />
    ).toBlob();
    await merger.add(await auditTrail.arrayBuffer());

    const mergedPdfBlob = await merger.saveAsBlob();
    return await mergedPdfBlob.arrayBuffer();
  };
  const auditTrailList = (value: string): string => {
    console.log('8');
    let newValue = value;
    const signedEvent = contractEventsData?.filter(
      (contractEventData: ContractEvent) =>
        contractEventData.status.toString() === EventStatuses.SIGNED.toString()
    );
    if (signedEvent.length > 0) {
      const signedEventString = signedEvent.map(
        (contractEventData: ContractEvent) => {
          let ipProp = '';
          if (contractEventData.ipInfo) {
            const json = JSON.parse(contractEventData.ipInfo);
            if (json) {
              ipProp = `<p>IP: ${json.ip}</p>
                        <p>Location: ${json.city}, ${json.country_name}</p>
                        <p>Timezone: ${json.timezone}, ${json.utc_offset}</p>`;
            }
          }
          const signFind = signsData.find(
            (signData) =>
              signData.email === contractEventData.email &&
              signData.fullName === contractEventData.name
          );
          return `
            <tr>
              <td> 
                <p><b>${contractEventData.name}</b></p>
                <p><b>${contractEventData.email}</b></p>
              </td> 
              <td>
                <p>
                  ${dayjs(contractEventData.createTime).format(
                    'YYYY-MM-DD @ HH:mm:ss'
                  )} GMT
                </p>
              </td>         
              <td>
                ${ipProp}
              </td>
              <td>
                <img src='${signFind ? signFind.base64 : ''}' alt="signature" />
              </td>
            </tr>`;
        }
      );
      newValue = `${newValue}
      <br>
      <h3 class="ql-align-center">Signature Certificate</h3>
      <br>
      <table>
      <colgroup><col width="500"><col width="500"></colgroup>
      <tbody>
        <tr>
          <td class="ql-size-small ql-align-center">Document ID</td>
          <td class="ql-size-small ql-align-center">Document name</td>
        </tr>
        <tr>
          <td class="ql-align-center"><b>${contract.controlLink}</b></td>
          <td class="ql-align-center"><b>${contract.contractName}</b></td>
        </tr>
      </tbody>
    </table>
      <br> 
        <table>
          <colgroup><col width="330"><col width="170"><col width="250"><col width="250"></colgroup>
          <tbody>
            <tr>
              <td class="ql-size-small">Signed by</td>
              <td class="ql-size-small">When</td>
              <td class="ql-size-small">Where</td>
              <td class="ql-size-small">Signature</td>
            </tr>
            ${signedEventString.join('')} 
          </tbody>
        </table>`;
    }

    return newValue;
  };

  const sendPdf = async (textSend?: any, needSave = true) => {
    console.log('9');
    if (needSave) {
      let textTmp = '';
      if (typeof textSend === 'string') {
        textTmp = textSend;
      } else {
        textTmp = quillRef.current.root.innerHTML;
      }
      await saveValue({
        shareLink: contract.shareLink,
        contractValue: textTmp,
      })
        .unwrap()
        .then((payload) => {
          dispatch(
            setContractValue({
              contractValue: payload.contractValue,
              changeTime: payload.changeTime,
            })
          );
        });
    }

    if (contract.contractType.toString() === ContractTypes.PDF.toString()) {
      const formData = new FormData();
      const pdfFile = (await getArrayBuffer('pdfFile')) as ArrayBuffer;

      const mergedPdf = await addPlaceholderToPdf(pdfFile);
      const blob = new Blob([mergedPdf], { type: 'application/pdf' });
      // let link = document.createElement('a');
      // link.href = window.URL.createObjectURL(blob);
      // let fileName = 'na2';
      // link.download = fileName;
      // link.click();
      formData.append('pdf', blob);
      await sendEmailPdf({ shareLink: contract.shareLink, data: formData });
    } else {
      await sendEmail({
        shareLink: contract.shareLink,
      });
    }
  };
  const saveValueDb = async (text: string) => {
    await saveValue({
      shareLink: contract.shareLink,
      contractValue: text,
    })
      .unwrap()
      .then((payload) => {
        dispatch(
          setContractValue({
            contractValue: payload.contractValue,
            changeTime: payload.changeTime,
          })
        );
      });
  };
  return (
    <Card style={{ opacity: 1 }} bordered={true} className="SharingCardToHide">
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Space direction="vertical" size={2} className="SharingDocHeader">
          {shareLinkView.toString() === ShareLinkView.SIGN.toString() && (
            <>
              <Title level={4} style={{ margin: '0 0 0 0' }}>
                Please, review and sign this document
              </Title>
              <Text type="secondary">Find the Sign button below. </Text>
            </>
          )}
          {shareLinkView.toString() === ShareLinkView.APPROVE.toString() && (
            <>
              <Title level={4} style={{ margin: '0 0 0 0' }}>
                Please, review and approve this document
              </Title>
              <Text type="secondary">Find the Approve button below. </Text>
            </>
          )}
          {shareLinkView.toString() === ShareLinkView.VIEW.toString() && (
            <>
              <Title level={4} style={{ margin: '0 0 0 0' }}>
                Please, review this document
              </Title>
            </>
          )}
        </Space>
        <Spin spinning={spinLoad}>
          {contract.contractType.toString() === ContractTypes.PDF.toString() ? (
            <PdfViewer
              // pagePlaceholders={contract.audit ? [] : currPagePlaceholders}
              pagePlaceholders={currPagePlaceholders}
              onLoad={(data) => {
                console.log('data', data, pagePlaceholders.current);
              }}
              forPrint={contract && contract.audit ? true : false}
            />
          ) : (
            <div id="scroll-container">
              <div id="editor-container" />
            </div>
          )}
          {!document.location.origin.includes('sendforsign.com') &&
            contract.contractType.toString() ===
              ContractTypes.PDF.toString() && (
              <Button
                style={{ display: 'none' }}
                onClick={() => sendPdf('', false)}
              >
                send pdf
              </Button>
            )}
        </Spin>
      </Space>
    </Card>
  );
};
